<template>
	<div>
		<div class="idps-container col-xl-8 d-flex flex-column flex-md-row">
			<card class="col-md-12 bg-red-ciip rounded" card-body-classes="d-flex flex-column justify-content-around">
				<h5 class="text-center text-other-idps">Moyens d'authentification</h5>

				<div class="d-flex flex-row justify-content-center mx-3">
					<card
						class="mx-3 text-center card-image-idp col-md-4"
						card-body-classes="p-0 d-flex flex-column justify-content-center">
						<a
							class="idp-img-achor d-flex justify-content-center align-items-center"
							:href="`${this.spURL}/Shibboleth.sso/Login?SAMLDS=1&target=${this.idmURL}/api/auth/saml?app=${this.resourceURL}&entityID=${this.idpURL}/idp/shibboleth`">
							<img class="idp-logo" alt="logo CIIP" src="static/img/idps/CIIP_logo_ecran_couleur.png" />
						</a>
					</card>
					<card
						class="text-center card-image-idp col-md-4"
						card-body-classes="p-0 d-flex flex-column justify-content-center">
						<a
							class="idp-img-achor d-flex justify-content-center align-items-center"
							:href="`${this.spURL}/Shibboleth.sso/Login?SAMLDS=1&target=${this.idmURL}/api/auth/saml?app=${this.resourceURL}&entityID=${this.edulogURL}`">
							<img class="idp-logo" alt="logo Edulog" src="static/img/idps/Edulog_RGB_Logo_Blue.png" />
						</a>
					</card>
				</div>
				<div class="d-flex flex-row test justify-content-center mx-3">
					<card
						class="text-center card-image-idp col-md-4"
						card-body-classes="p-0 d-flex flex-column justify-content-center">
						<a
							class="idp-img-achor d-flex justify-content-center align-items-center"
							:href="`${this.spURL}/Shibboleth.sso/Login?SAMLDS=1&target=${this.idmURL}/api/auth/saml?app=${this.resourceURL}&entityID=${this.rpnURL}`">
							<img class="idp-logo" alt="logo RPN" src="static/img/idps/logo_rpn_2.png" />
						</a>
					</card>
					<card
						class="mx-3 text-center card-image-idp col-md-4"
						card-body-classes="p-0 d-flex flex-column justify-content-center">
						<a
							class="idp-img-achor d-flex justify-content-center align-items-center"
							:href="`${this.spURL}/Shibboleth.sso/Login?SAMLDS=1&target=${this.idmURL}/api/auth/saml?app=${this.resourceURL}&entityID=${this.geneveURL}`">
							<img class="idp-logo" alt="logo Genève" src="static/img/idps/logoGE.png" />
						</a>
					</card>
					<card
						class="text-center card-image-idp col-md-4"
						card-body-classes="p-0 d-flex flex-column justify-content-center">
						<a
							class="idp-img-achor d-flex justify-content-center align-items-center"
							:href="`${this.spURL}/Shibboleth.sso/Login?SAMLDS=1&target=${this.idmURL}/api/auth/saml?app=${this.resourceURL}&entityID=${this.eduVdURL}`">
							<img class="idp-logo" alt="logo CIPEO" src="static/img/idps/logo_cipeo.png" />
						</a>
					</card>
				</div>
				<div class="d-flex flex-row justify-content-center mx-3">
					<card
						class="text-center card-image-idp col-md-4"
						card-body-classes="p-0 d-flex flex-column justify-content-center">
						<a
							class="idp-img-achor d-flex justify-content-center align-items-center"
							:href="`${this.spURL}/Shibboleth.sso/Login?SAMLDS=1&target=${this.idmURL}/api/auth/saml?app=${this.resourceURL}&entityID=${this.eduVaudURL}`">
							<img class="idp-logo" alt="logo DGEP" src="static/img/idps/logo_dgep.png" />
						</a>
					</card>
					<card
						class="mx-3 text-center card-image-idp col-md-4"
						card-body-classes="p-0 d-flex flex-column justify-content-center">
						<a
							class="idp-img-achor d-flex justify-content-center align-items-center"
							:href="`${this.spURL}/Shibboleth.sso/Login?SAMLDS=1&target=${this.idmURL}/api/auth/saml?app=${this.resourceURL}&entityID=${this.ictvsURL}`">
							<img class="idp-logo" alt="logo ICTVS" src="static/img/idps/logo_ictvs.png" />
						</a>
					</card>
					<card
						class="text-center card-image-idp col-md-4"
						card-body-classes="p-0 d-flex flex-column justify-content-center">
						<a
							class="idp-img-achor d-flex justify-content-center align-items-center"
							:href="`${this.spURL}/Shibboleth.sso/Login?SAMLDS=1&target=${this.idmURL}/api/auth/saml?app=${this.resourceURL}&entityID=${this.education21URL}`">
							<img class="idp-logo" alt="logo Education 21" src="static/img/idps/logo_education21.png" />
						</a>
					</card>
				</div>
				<div class="d-flex flex-row justify-content-center">
					<card
						class="text-center card-image-idp flex-fill col-md-12"
						card-body-classes="p-0 d-flex flex-columnjustify-content-center">
						<div id="wayf" ref="wayf"></div>
					</card>
				</div>
			</card>
		</div>
	</div>
</template>

<script>
import { RESOURCES } from '@/enums/Resources';
import { Card } from '@/components/Template';

export default {
	components: {
		Card,
	},
	created() {
		// Switch AAI
		window.wayf_URL = 'https://wayf.switch.ch/SWITCHaai/WAYF';
		window.wayf_sp_entityID = `${this.$myenv.REMOTE_SP_IDM_WEBAPP_URL}/shibboleth`;
		window.wayf_sp_handlerURL = `${this.$myenv.REMOTE_SP_IDM_WEBAPP_URL}/Shibboleth.sso`;

		window.wayf_hide_categories = ['all'];
		window.wayf_unhide_idps = [
			'https://aai-login.hepl.ch/idp/shibboleth',
			'https://aai-loginhep.edufr.ch/idp/shibboleth',
			'https://aai-login.hep-bejune.ch/idp/shibboleth',
			'https://aai-logon.hepvs.ch/idp/shibboleth',
			'https://aai-login.phbern.ch/idp/shibboleth',
			'https://idp.unige.ch/idp/shibboleth',
			'https://aai.unifr.ch/idp/shibboleth',
			'https://aai.unil.ch/idp/shibboleth',
		];
		window.wayf_use_improved_drop_down_list = true;
		window.wayf_show_remember_checkbox = false;
		window.wayf_border_color = '#fff';
		window.wayf_background_color = '#fff';
	},
	data() {
		return {
			resourceURL: this.resourceLink,
			form: {
				email: null,
				password: null,
			},
			errorMessage: null,
			targetUrl: '',
			idmURL: this.$myenv.IDM_API_URL,
			spURL: this.$myenv.REMOTE_SP_IDM_WEBAPP_URL,
			idpURL: this.$myenv.IDP_URL,
			edulogURL: this.$myenv.EDULOG_URL,
			rpnURL: this.$myenv.RPN_URL,
			geneveURL: this.$myenv.GENEVE_URL,
			eduVaudURL: this.$myenv.EDU_VAUD_URL,
			eduVdURL: this.$myenv.EDU_VD_URL,
			ictvsURL: this.$myenv.ICTVS_URL,
			education21URL: this.$myenv.EDUCATION21_URL,
		};
	},
	props: {
		resourceLink: {
			type: String,
		},
		resourceName: {
			type: String,
		},
	},
	mounted() {
		this.localhostAPP();
		this.createWayf();
	},
	watch: {
		resourceLink: {
			handler(newVal) {
				if (newVal) {
					this.resourceURL = newVal;
					document.getElementById('wayf').innerHTML = '';
					this.createWayf();
				}
			},
		},
	},
	computed: {
		showOauth2: function () {
			const apps = RESOURCES.filter((app) => app.name === this.resourceName);
			// If comming from an app not describe in RESOURCES, do not show google and facebook. If working on localhost, add true
			return apps.length > 0 ? apps[0].oAuth : false;
		},
	},
	methods: {
		localhostAPP() {
			if (window.location.hostname === 'localhost') {
				this.resourceURL = `http://localhost:${window.location.port}`;
			}
		},
		ciipLogin() {
			window.location = `${this.spURL}/Shibboleth.sso/Login?SAMLDS=1&target=${this.idmURL}/api/auth/saml?app=${this.resourceURL}&entityID=https://${this.idpURL}/idp/shibboleth`;
		},
		createWayf() {
			this.targetUrl = `${this.$myenv.IDM_API_AUTH_URL}/api/auth/saml?app=${this.resourceURL}`;
			function DisplayWrite(a) {
				if (document.body) {
					document.getElementById('wayf').insertAdjacentHTML('beforeend', a);
				} else {
					document.writeclear(a);
				}
			}

			document.writeclear = document.write;
			document.write = (x) => {
				DisplayWrite(x);
			};
			window.wayf_return_url = this.targetUrl;

			const wayf = document.createElement('script');

			wayf.setAttribute('src', 'https://wayf.switch.ch/SWITCHaai/WAYF/embedded-wayf.js');
			this.$refs.wayf.append(wayf);

			wayf.onload = () => {
				this.updateSubmitButton();
			};
		},
		updateSubmitButton() {
			document.getElementById('wayf_submit_button').remove();
			document.getElementById('IdPList').innerHTML +=
				'<button id="wayf_submit_button" type="submit" name="Login" value="" accesskey="s">' +
				'<svg id="wayf_submit_button_icon" fill="#b4001a" width="25px" height="25px" viewBox="0 0 1920 1920" xmlns="http://www.w3.org/2000/svg">\n' +
				'    <path d="M1072.941 113C1540.066 113 1920 492.934 1920 960.059c0 467.125-379.934 847.059-847.059 847.059-256.15 0-495.247-113.845-656.075-312.283l87.868-71.153c139.257 171.897 346.278 270.494 568.207 270.494 404.781 0 734.118-329.336 734.118-734.117 0-404.781-329.337-734.118-734.118-734.118-221.93 0-428.95 98.598-568.207 270.494l-87.868-71.153C577.694 226.845 816.79 113 1072.94 113ZM943.443 524.885l435.162 435.163-435.162 435.162-79.85-79.737 298.956-298.955H.045V903.577H1162.55L863.593 604.735l79.85-79.85Z" fill-rule="evenodd"/>\n' +
				'</svg>' +
				'</button>';
		},
		setOAuth2State() {
			if (this.$store.getters['account/oauth2']) {
				this.$store.dispatch('account/setStateOAuth2', false);
			} else {
				this.$store.dispatch('account/setStateOAuth2', true);
			}
		},
	},
};
</script>

<style lang="scss" scoped>
.idps-container {
	height: 450px;
}

.card {
	margin-bottom: 15px;
}

.hr-text {
	line-height: 1em;
	position: relative;
	outline: 0;
	border: 0;
	color: black;
	text-align: center;
	height: 1.5em;
	opacity: 0.5;
	// Ajouter ce champs dès qu'il y a plus de 3 logo
	//margin-top: 3em;
	&:before {
		content: '';
		// use the linear-gradient for the fading effect
		// use a solid background color for a solid bar
		background: linear-gradient(to right, transparent, #000000, transparent);
		position: absolute;
		left: 0;
		top: 50%;
		width: 100%;
		height: 1px;
	}
	&:after {
		content: attr(data-content);
		font-size: 0.75em;
		position: relative;
		display: inline-block;
		padding: 0 0.5em;
		line-height: 2em;
		// this is really the only tricky part, you need to specify the background color of the container element...
		color: #000000;
		background-color: #fcfcfa;
	}
}

$border-radius: 12px;
.login-input {
	margin-bottom: 10px;
}

.text-switchaai,
.text-other-idps {
	font-size: 1.2em;
}

.text-other-idps {
	color: #fff;
}

.card-image-idp {
	height: 75px;
	width: 190.5px;
	box-shadow: none;
}

/* For Oauth logos */

.card-image-idp-oauth {
	border-radius: 100%;
	box-shadow: none;
}

.card-image-idp-oauth img:hover {
	box-shadow: 0 6px 10px -2px rgba(0, 0, 0, 0.15);
	border-radius: 100%;
}

.idp-logo {
	max-height: 75px;
	padding: 10px 10px 10px 10px;
}

.idp-logo-oauth {
	max-height: 35px;
	max-width: 150.5px;
}

/* For all logos */

.idp-img-achor {
	max-width: 100%;
	max-height: 100%;
}

/* Others */

.error-message {
	color: darkred;
	padding-top: 10px;
}

@media screen and (min-width: 1536px) {
	.idps-container {
		flex: 0 0 50%;
		max-width: 50%;
	}
}

@media screen and (max-width: 767px) {
	.login-left-container {
		border-radius: $border-radius $border-radius 0 0;
		margin-bottom: 0;
		padding-bottom: 1em;
	}

	.login-right-container {
		border-radius: 0 0 $border-radius $border-radius;
		margin-bottom: 120px;
	}
}
</style>

<style lang="scss">
.input-group-text {
	border: 1px solid #ced4da !important;
	border-right: none !important;
}

#wayf {
	width: 100%;
	display: flex;
	align-items: center;
}

#wayf_div {
	padding: 0 !important;
	border-radius: 12px;
	width: 100% !important;
	display: flex;

	#wayf_logo_div {
		padding: 0;
		float: none !important;
		display: flex;
		align-items: center;

		#wayf_logo {
			margin: 0 !important;
			width: 80% !important;
			float: none !important;
		}
	}

	#wayf_logo_div > a {
		margin: 0 !important;
	}

	#wayf_intro_label {
		padding: 5px;
		color: #212529 !important;
	}

	#IdPList {
		display: flex;
		width: 100%;
		margin-right: 5px;
		border: 1px solid #dddddd;
		border-radius: 4px;
	}

	#user_idp_iddwrap {
		width: 100%;
		margin-right: 5px;
	}

	#user_idp_iddtext {
		border: none;
		color: #66615b;
		height: auto !important;
		padding: 10px !important;
		width: 100% !important;
	}

	#user_idp_iddtext:hover {
		box-shadow: none !important;
		-webkit-box-shadow: none !important;
		cursor: pointer;
	}

	#user_idp_iddlist {
		padding: 0px !important;
		-webkit-box-shadow: none !important;
		cursor: pointer;
	}

	#user_idp_iddicon {
		display: none !important;
	}

	#wayf_submit_button {
		background-color: white;
		border: none;
		cursor: pointer;
		font-size: 0.8571em;
		font-weight: 600;
		line-height: 1.35em;
		text-transform: uppercase;
		margin: 0 !important;
	}

	#wayf_submit_button:focus {
		outline: none;
	}

	#wayf_submit_button_icon {
		margin-right: 5px;
	}

	#wayf_intro_label {
		display: none;
	}
}

.bg-red-ciip {
	background-color: #b4001a !important;
}
</style>
